
export default {
  name: 'BaseShareButton',
  props: {
    entity: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'default',
    },
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      isShow: false,
      isCopyLink: false,
    }
  },
  methods: {
    close() {
      this.isShow = false
    },
    async copyUrl(link) {
      if (typeof navigator.clipboard === 'undefined') {
        const textArea = document.createElement('textarea')
        textArea.value = link
        textArea.style.position = 'fixed'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          document.execCommand('copy')
          this.isCopyLink = true
        } catch (err) {
          this.isCopyLink = false
        }
        document.body.removeChild(textArea)
        return
      }
      await navigator.clipboard.writeText(link).then(() => {
        this.isCopyLink = true
        this.close()
      })
    },
  },
}
