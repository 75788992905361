
export default {
  name: 'MegasredaContentPostHeaderUserMenu',
  props: {
    post: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      isShowMenu: false,
      isDraftedProcces: false,
    }
  },
  computed: {
    isAllowShowMenu() {
      if (!this.$auth?.loggedIn) {
        return false
      } else {
        return (
          this.post?.author?._id === this.$auth.user._id ||
          this.$auth.user.roles.find((role) => role === 'ADMIN') ||
          this.$auth.user.roles.find((role) => role === 'MODERATOR')
        )
      }
    },
  },
  methods: {
    closeMenu() {
      this.isShowMenu = false
    },
    async postToDraft() {
      this.isDraftedProcces = true
      await this.$axios.$put(`/article/drafted/${this.post._id}`)
      this.isDraftedProcces = false
      this.closeMenu()
      this.$emit('update')
    },

    async removePost() {
      this.isShowMenu = false
      const isRemove = await this.$refs.confirmDialogue.show({
        title: 'Удаление материала',
        message: 'Вы уверены? Эту операцию невозможно отменить.',
        okButton: 'Удалить материал',
      })
      if (isRemove) {
        await this.$axios.$delete(`/article/${this.post._id}`)
        this.$emit('remove')
      }
      this.closeMenu()
    },
  },
}
