
export default {
  name: 'MegasredaContentVideoFeedCard',
  props: {
    post: {
      type: Object,
      default: null,
    },
    isHasComment: {
      type: Boolean,
      default: false,
    },
    draft: {
      type: Boolean,
      default: false,
    },
    statistics: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      streamsVisibleCount: 3,
      isSetShow: false,
    }
  },
  computed: {
    isAuthor() {
      if (this.post?.author?._id) {
        return this.$auth?.user?._id === this.post.author._id
      } else {
        return false
      }
    },
    thematicStreams() {
      if (this.post?.streams) {
        return this.post?.streams?.filter(
          (stream) => stream.type === 'THEMATIC'
        )
      } else {
        return []
      }
    },
    shareTitle() {
      if (this.post) {
        return this.post?.title
          ? this.post.title.replace(/<a[^>]*>|<\/a>/gi, '')
          : ''
      } else {
        return ''
      }
    },
    shareDescription() {
      if (this.post) {
        return this.post?.description?.replace(/<a[^>]*>|<\/a>/gi, '')
      } else {
        return ''
      }
    },
    isClient() {
      if (this.post?.author) {
        return !!this.post?.author?.isClient
      } else {
        return false
      }
    },
    isAdmin() {
      if (this.post?.author) {
        return !!this.$auth?.user?.roles?.some((role) =>
          ['ADMIN', 'MODERATOR'].includes(role)
        )
      } else {
        return false
      }
    },
    streams() {
      if (this.thematicStreams?.length) {
        return this.thematicStreams?.slice(0, this.streamsVisibleCount)
      } else {
        return []
      }
    },
  },
  methods: {
    inView({ isInView }) {
      if (isInView && !this.isSetShow && !this.draft) {
        this.setShow()
      }
    },
    setShow() {
      this.$axios.$post('/activity/show/article', {
        username: this.post.author.username,
        articleId: this.post._id,
      })
      this.isSetShow = true
    },
    closeMenu() {
      this.isShowStreamsPopUp = false
    },
  },
}
