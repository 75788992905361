import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=5a4998f1&"
import script from "./UserMenu.vue?vue&type=script&lang=js&"
export * from "./UserMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,BaseModalConfirm: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Modal/Confirm/Confirm.vue').default})
