
export default {
  name: 'BaseShareTelegram',
  props: {
    entity: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
}
